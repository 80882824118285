import React, { Component } from 'react';
import { Layout, Button } from 'antd';
import './Presentation.css';

import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import {EffectComposer, RenderPass, BloomEffect, EffectPass, ShaderPass} from "postprocessing";
import {GammaCorrectionShader} from "three/examples/jsm/shaders/GammaCorrectionShader";
import {AdaptiveToneMappingPass} from "three/examples/jsm/postprocessing/AdaptiveToneMappingPass";
import {SSAARenderPass} from "three/examples/jsm/postprocessing/SSAARenderPass";
import {CopyShader} from "three/examples/jsm/shaders/CopyShader";

import {WebGLRenderTarget} from "three/src/renderers/WebGLRenderTarget";

import {
    PlaneBufferGeometry, Color,
    DirectionalLight, DirectionalLightHelper,
    HemisphereLight, HemisphereLightHelper,
    SpotLight, SpotLightHelper,
    AmbientLight,
    Mesh, BoxGeometry,
    MeshStandardMaterial, ShadowMaterial, PCFSoftShadowMap,
    PerspectiveCamera, ACESFilmicToneMapping, Scene,
    WebGLRenderer,
    LinearFilter, RGBAFormat, HalfFloatType
} from "three";

let scene;

class Human extends Component {
    mount;

    buildCamera = () => {
        var camera = new PerspectiveCamera( 35, (window.innerWidth > 970 ? 970 : window.innerWidth-30) / (window.innerHeight > 545.63 ? 545.63 : window.innerHeight-30), 0.001, 1000 );
        camera.position.set( 0, 0.23, 0.8 );

        return camera;
    }

    buildScene = (camera) => {
        let scene = new Scene();
        //scene.background = new Color(0x333333);
        scene.background = new Color(0xdddddd);

        scene.add( camera );

        // light
        const ambLight = new AmbientLight( 0x404040, 0.6 ); // soft white light
        scene.add( ambLight );

        let hemiLight = new HemisphereLight(0xffeeb1, 0x080820, 0.5);
        scene.add(hemiLight);

        let spotLight = new SpotLight(0xffffff, 1);
        spotLight.position.set(-0.50,0.50,0.50);
        spotLight.castShadow = true;
        spotLight.shadow.camera.near = 0.001850;
        spotLight.shadow.camera.far = 10;
        spotLight.shadow.camera.fov = 26;
        spotLight.shadow.darkness = 0.8;
        spotLight.shadow.mapSize.width = 1024*4;
        spotLight.shadow.mapSize.height = 1024*4;
        scene.add( spotLight );
        camera.add( spotLight );
        //const spotLightHelper = new SpotLightHelper( spotLight );
        //scene.add( spotLightHelper );

        let keyLight = new DirectionalLight( 0xffffff, 0.3 ); //display: 0xff0000
        keyLight.position.set(0.7,0.35,0.35);
        keyLight.lookAt(0,0,0);
        scene.add( keyLight );
        camera.add( keyLight );

        let fillLight = new DirectionalLight( 0xffffff, 0.03 ); //display: 0x0000ff
        fillLight.position.set(0.7,-0.35,0.7);
        fillLight.lookAt(0,0,0);
        scene.add( fillLight );
        camera.add( fillLight );

        let rimLight = new DirectionalLight( 0xffffff, 1.0 ); //display: 0xffff00
        rimLight.position.set(-0.35,0.70,-0.35);
        rimLight.lookAt(0,0,0);
        scene.add( rimLight );
        camera.add( rimLight );
        //const rimLightHelper = new DirectionalLightHelper( rimLight );
        //scene.add( rimLightHelper );

        return scene;
    }

    buildRendered = (camera) => {
        let renderer = new WebGLRenderer( { antialias: true } );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize(window.innerWidth > 970 ? 970 : window.innerWidth-30, window.innerHeight > 545.63 ? 545.63 : window.innerHeight-30 );
        renderer.toneMapping = ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = PCFSoftShadowMap;

        return renderer;
    }

    buildControls = (camera, renderer) => {
        let controls = new OrbitControls( camera, renderer.domElement );
        controls.target.set(0, 0.23, 0);
        controls.minDistance = 0.05;
        controls.maxDistance = 5;
        controls.maxPolarAngle = Math.PI / 2;

        return controls;
    }

    buildGround = () => {
        const groundGeometry = new PlaneBufferGeometry( 10, 10, 32, 32 );
        const groundMaterial = new ShadowMaterial();
        groundMaterial.opacity = 0.1;
        const ground = new Mesh( groundGeometry, groundMaterial );
        ground.rotateX( - Math.PI / 2 );
        ground.receiveShadow = true;
        scene.add( ground );

        return ground;
    }

    buildBox = () => {
        const geometry = new BoxGeometry( 1, 1, 1 );
        const material = new MeshStandardMaterial({color: 'red'});
        const box = new Mesh( geometry, material );
        box.receiveShadow = true;
        box.castShadow = true;

        return box;
    }

    buildHuman = () => {
        let url = 'Female_body_geo.OBJ';
        const loader = new OBJLoader();
        loader.load(url, ( human ) => {
                human.scale.set(0.025, 0.025, 0.025);
                human.castShadow = true;
                human.receiveShadow = true;
                human.traverse(function(child){child.castShadow = true;});
                scene.add( human );
            },
            ( xhr ) => {
                console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' ); // called when loading
            },
            ( error ) => {
                console.log( 'An error happened' ); // called when loading has errors
            });

    }

    loadAnomalies = () => {
        let url = 'Anomalies.obj';
        const loader = new OBJLoader();
        loader.load(url, ( anomalies ) => {
                anomalies.scale.set(0.025, 0.025, 0.025);
                anomalies.receiveShadow = true;
                // anomalies.traverse(function(child){child.castShadow = true;});
                anomalies.traverse( child => {
                    child.castShadow = true;
                    if ( child.material ) child.material = new MeshStandardMaterial({color: 'red'});

                } );
                //const anomalies_mat = new MeshBasicMaterial({color: 'red'});
                //anomalies = new Mesh( anomalies, anomalies_mat );
                scene.add( anomalies );

            },
            ( xhr ) => {
                console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' ); // called when loading
            },
            ( error ) => {
                console.log( 'An error happened' ); // called when loading has errors
            });

    }

    componentDidMount() {
        let  camera, controls, renderer, composer, ground, woman, animate;

        camera = this.buildCamera();
        scene = this.buildScene(camera);
        renderer = this.buildRendered();
        controls = this.buildControls(camera, renderer);
        ground = this.buildGround();
        //woman = this.buildHuman();

        composer = new EffectComposer( renderer );
        composer.addPass( new RenderPass( scene, camera ) );
        composer.addPass( new EffectPass( camera, new BloomEffect() ) );
        composer.addPass( new ShaderPass( GammaCorrectionShader ) );

        window.addEventListener( 'resize', onWindowResize, false );
        this.mount.appendChild( renderer.domElement );

        function onWindowResize() {
            camera.aspect = (window.innerWidth > 970 ? 970 : window.innerWidth-30) / (window.innerHeight > 545.63 ? 545.63 : window.innerHeight-30);
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth > 970 ? 970 : window.innerWidth-30, window.innerHeight > 545.63 ? 545.63 : window.innerHeight-30 );
        }

        animate = () => {
            renderer.render( scene, camera );
            composer.render();
            controls.update();
            requestAnimationFrame( animate );

        };
        animate();
    }

    render() {
        return (
            <div>
                <div id="content-block" style={{padding:10}}>
                    <Button onClick={() => this.buildHuman()}>Load Human</Button>
                    <Button onClick={() => this.loadAnomalies()}>Load Anomalies</Button>
                    {/*<Button onClick={() => LoadToFile()}>Load to File</Button> */}
                    {/*<Button onClick={() => DoNext()}>Stream Next Section</Button>*/}
                    {/*<Button onClick={() => DoAll()}>Stream All Sections</Button>*/}
                </div>
                <div ref={ref => (this.mount = ref)} style={{paddingRight:10}} />
                <div style={{padding:10}} />
            </div>
        );
    }
}

export default Human;
