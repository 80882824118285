import React, { Component } from 'react';
import { Layout, Button } from 'antd';
import './Presentation.css';

import {VOXLoader} from "../loader/default/VOXLoader";
import {VOXLoader as VOXLoaderAvaer}  from "../loader/avaer/modules/VOXLoader";

import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import {
    BoxBufferGeometry, SphereBufferGeometry, PlaneBufferGeometry, Color,
    DirectionalLight,
    HemisphereLight,
    InstancedMesh, Mesh, Matrix4,
    MeshStandardMaterial, ShadowMaterial, PCFSoftShadowMap,
    PerspectiveCamera, ACESFilmicToneMapping, Scene, SpotLight,
    WebGLRenderer
} from "three";
import * as fs from 'fs'

/* HEX  */
const formatVox =  require('@sh-dave/format-vox');
const Reader = formatVox.VoxReader;
const Nodes = formatVox.VoxNodeTools;
const Tools = formatVox.VoxTools;

// const fs = require('fs');

let indent = 0;

const ind = ( o = 0 ) => Array(indent + o).join(' ');

const dictData = ( dict ) => {
    let result = '';

    if (Tools.dictHasTranslation(dict)) {
        const t = Tools.getTranslationFromDict(dict);
        result += ` translation: ${t.x}/${t.y}/${t.z}; `;
    }

    if (Tools.dictHasRotation(dict)) {
        const r = Tools.getRotationFromDict(dict);
        result += ` rotation: ${r._00}/${r._10}/${r._20}/${r._01}/${r._11}/${r._21}/${r._02}/${r._12}/${r._22}; `;
    }

    return result.length > 0 ? `(${result}) ` : result;
}

const walker = {
    beginGraph: vox => {
    },

    endGraph: vox => {
    },

    // can have a Group or Shape as child
    onTransform: attributes => {
        console.log(`${ind()}transform ${dictData(attributes)}`);
        indent += 2;
    },

    beginGroup: attributes => {
        console.log(`${ind()}group ${dictData(attributes)}`);
        indent += 2;
    },

    endGroup: () => {
        indent -= 2;
        console.log(`${ind()}/group`);
    },

    onShape: (attributes, models) => {
        console.log(`${ind()}shape ${dictData(attributes)} {`);
        //console.log(dictData);
        //console.log(attributes);
        console.log(JSON.stringify(models));
        console.log(`${ind(2)}models: [${models.map(m => m.modelId).join(',')}];`);
        console.log(`${ind()}}`);
        indent -= 2;
    }
}
/* END OF HEX*/

let scene;
let loadedChunks;
let globalPallete;

let shiftVectors=[
    {xx:  0,yy:  0,zz:  0},{xx:125,yy:  0,zz:  0},{xx:250,yy:  0,zz:  0},{xx:375,yy:  0,zz:  0},{xx:500,yy:  0,zz:  0},{xx:625,yy:  0,zz:  0},{xx:750,yy:  0,zz:  0},{xx:875,yy:  0,zz:  0}, // 0-7
    {xx:  0,yy:  0,zz:112},{xx:125,yy:  0,zz:112},{xx:250,yy:  0,zz:112},{xx:375,yy:  0,zz:112},{xx:500,yy:  0,zz:112},{xx:625,yy:  0,zz:112},{xx:750,yy:  0,zz:112},{xx:875,yy:  0,zz:112}, // 8-15
                                                                         {xx:375,yy:  0,zz:224},{xx:500,yy:  0,zz:224},{xx:625,yy:  0,zz:224},{xx:750,yy:  0,zz:224},                        // 16-19

    {xx:  0,yy: 95,zz:  0},{xx:125,yy: 95,zz:  0},{xx:250,yy: 95,zz:  0},{xx:375,yy: 95,zz:  0},{xx:500,yy: 95,zz:  0},{xx:625,yy: 95,zz:  0},{xx:750,yy: 95,zz:  0},{xx:875,yy: 95,zz:  0}, // 20-27
    {xx:  0,yy: 95,zz:112},{xx:125,yy: 95,zz:112},{xx:250,yy: 95,zz:112},{xx:375,yy: 95,zz:112},{xx:500,yy: 95,zz:112},{xx:625,yy: 95,zz:112},{xx:750,yy: 95,zz:112},{xx:875,yy: 95,zz:112}, // 28-35
                                                  {xx:250,yy: 95,zz:224},{xx:375,yy: 95,zz:224},{xx:500,yy: 95,zz:224},{xx:625,yy: 95,zz:224},{xx:750,yy: 95,zz:224},{xx:875,yy: 95,zz:224}, // 36-41
                                                                         {xx:375,yy: 95,zz:336},{xx:500,yy: 95,zz:336},{xx:625,yy: 95,zz:336},{xx:750,yy: 95,zz:336},{xx:875,yy: 95,zz:336}, // 42-46

    {xx:  0,yy:190,zz:  0},{xx:125,yy:190,zz:  0},{xx:250,yy:190,zz:  0},{xx:375,yy:190,zz:  0},{xx:500,yy:190,zz:  0},{xx:625,yy:190,zz:  0},{xx:750,yy:190,zz:  0},{xx:875,yy:190,zz:  0}, // 47-54
    {xx:  0,yy:190,zz:112},{xx:125,yy:190,zz:112},{xx:250,yy:190,zz:112},{xx:375,yy:190,zz:112},{xx:500,yy:190,zz:112},{xx:625,yy:190,zz:112},{xx:750,yy:190,zz:112},{xx:875,yy:190,zz:112}, // 55-62
                                                  {xx:250,yy:190,zz:224},{xx:375,yy:190,zz:224},{xx:500,yy:190,zz:224},                       {xx:750,yy:190,zz:224},{xx:875,yy:190,zz:224}, // 63-67
                                                                         {xx:375,yy:190,zz:336},{xx:500,yy:190,zz:336},                       {xx:750,yy:190,zz:336},{xx:875,yy:190,zz:336}, // 68-71

    {xx:  0,yy:285,zz:  0},{xx:125,yy:285,zz:  0},{xx:250,yy:285,zz:  0},{xx:375,yy:285,zz:  0},{xx:500,yy:285,zz:  0},{xx:625,yy:285,zz:  0},{xx:750,yy:285,zz:  0},{xx:875,yy:285,zz:  0}, // 72-79
    {xx:  0,yy:285,zz:112},{xx:125,yy:285,zz:112},{xx:250,yy:285,zz:112},{xx:375,yy:285,zz:112},{xx:500,yy:285,zz:112},{xx:625,yy:285,zz:112},{xx:750,yy:285,zz:112},{xx:875,yy:285,zz:112}, // 80-87
                                                  {xx:250,yy:285,zz:224},{xx:375,yy:285,zz:224},{xx:500,yy:285,zz:224},{xx:625,yy:285,zz:224},{xx:750,yy:285,zz:224},{xx:875,yy:285,zz:224}, // 88-93
                                                                                                {xx:500,yy:285,zz:336},{xx:625,yy:285,zz:336},{xx:750,yy:285,zz:336},{xx:875,yy:285,zz:336}, // 93-96

];
let geometry, material, matrix;

let chunkNumber = 0;
function renderChunk(scene, geometry, material, matrix, chunk, shiftVector, chunkNumber) {
    console.log(chunk);
    console.log("Chunk number: " + chunkNumber);

    const size = chunk.size;
    const data = chunk.data;
    // const palette = chunk.palette;
    const palette = globalPallete;

    //displayPalette( palette );

    const mesh = new InstancedMesh( geometry, material, data.length / 4 );
    mesh.scale.setScalar( 0.002 );
    //mesh.scale.setScalar(Math.random() * (0.0025 - 0.0019) + 0.002);
	mesh.castShadow = true;
	mesh.receiveShadow = true;
    scene.add( mesh );

    let shift = 0;
    let {xx, yy, zz} = shiftVector;
    //let xx = chunkNumber % 8 * 125;
    //let yy = Math.floor(chunkNumber/16) * 95;
    //let zz = Math.floor(chunkNumber/8) * 112;
    console.log(`{xx, yy, zz}: {${xx}, ${yy}, ${zz}}`);

    for ( let j = 0, k = 0; j < data.length; j += 4, k ++ ) {

        const x = data[ j + 0 ] - size.x / 2 + shift + xx;
        const y = data[ j + 1 ] - size.y / 2 + shift + yy;
        const z = data[ j + 2 ] - size.z / 2 + shift + zz;
        //var c = data[ j + 3 ];

        let c;
        if (data[ j + 3 ] < 0) {
        	c = data[ j + 3 ] + 256;
        }
        else {
        	c = data[ j + 3 ];
        };

        const hex = palette[ c ];
        const r = ( hex >> 0 & 0xff ) / 0xff;
        const g = ( hex >> 8 & 0xff ) / 0xff;
        const b = ( hex >> 16 & 0xff ) / 0xff;

        // console.log(x)
        const color = new Color();
        mesh.setColorAt( k, color.setRGB( r, g, b ) );
        mesh.setMatrixAt( k, matrix.setPosition( -x, z, -y ) );
        //mesh.setMatrixAt( k, matrix.setPosition( x, y, z ) );
        mesh.matrixAutoUpdate = false;
        mesh.updateMatrix();
    }
}

function displayPalette( palette ) {
    const canvas = document.createElement( 'canvas' );
    canvas.width = 8;
    canvas.height = 32;
    canvas.style.position = 'absolute';
    canvas.style.top = '130px';
    canvas.style.width = '100px';
    canvas.style.imageRendering = 'pixelated';
    document.body.appendChild( canvas );
    const context = canvas.getContext( '2d' );
    for ( var c = 0; c < 256; c ++ ) {
        const x = c % 8;
        const y = Math.floor( c / 8 );
        const hex = palette[ c + 1 ];
        const r = hex >> 0 & 0xff;
        const g = hex >> 8 & 0xff;
        const b = hex >> 16 & 0xff;
        context.fillStyle = `rgba(${r},${g},${b},1)`;
        context.fillRect( x, 31 - y, 1, 1 );
    }
}

function DoNext() {
    if(chunkNumber && chunkNumber == loadedChunks.length) {
        // no more chunks
        return;
    }
    const chunk = loadedChunks[chunkNumber];
    const shiftVector = shiftVectors[chunkNumber];
    console.log(scene);
    renderChunk(scene, geometry, material, matrix, chunk, shiftVector, chunkNumber);
    chunkNumber++;
}

function DoAll() {
    if(chunkNumber && chunkNumber == loadedChunks.length) {
        // no more chunks
        return;
    }
    for ( var c = 0; c < loadedChunks.length; c ++ ) {
        const chunk = loadedChunks[c];
        const shiftVector = shiftVectors[c];
        console.log(scene);
        renderChunk(scene, geometry, material, matrix, chunk, shiftVector, chunkNumber);
    }
}

function LoadToFile() {
    function download(content, fileName, contentType) {
        var a = document.createElement("a");
        var file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    var jsonData = JSON.stringify(loadedChunks[chunkNumber]);
    download(jsonData, 'chunk.json', 'json/plain');
}

class Shoes extends Component {
    mount;

    buildCamera = () => {
        let camera = new PerspectiveCamera( 50, (window.innerWidth > 970 ? 970 : window.innerWidth-30) / (window.innerHeight > 545.63 ? 545.63 : window.innerHeight-30), 0.001, 1000 );
        camera.position.set( 0.75, 0.35, 0.75 ); // 0.0xx

        return camera;
    }

    buildScene = (camera) => {
        console.log("sd")
        // scene
        let scene = new Scene();
        scene.background = new Color(0xdddddd);

        scene.add( camera );

        // light
        const hemiLight = new HemisphereLight(0xffeeb1, 0x080820, 1);
        scene.add(hemiLight);

        var spotLight = new SpotLight(0xffffff, 1);
        spotLight.position.set(-0.50,0.50,0.50);
        spotLight.castShadow = true;
        spotLight.shadow.camera.near = 0.001850;
        spotLight.shadow.camera.far = 10;
        spotLight.shadow.camera.fov = 26;
        spotLight.shadow.darkness = 0.8;
        spotLight.shadow.mapSize.width = 1024*4;
        spotLight.shadow.mapSize.height = 1024*4;
        scene.add( spotLight );
        camera.add( spotLight );

        var keyLight = new DirectionalLight( 0xffffff, 0.3 ); //display: 0xff0000
        keyLight.position.set(0.7,0.35,0.35);
        keyLight.lookAt(0,0,0);
        scene.add( keyLight );
        camera.add( keyLight );

        var fillLight = new DirectionalLight( 0xffffff, 0.03 ); //display: 0x0000ff
        fillLight.position.set(0.7,-0.35,0.7);
        fillLight.lookAt(0,0,0);
        scene.add( fillLight );
        camera.add( fillLight );

        var rimLight = new DirectionalLight( 0xffffff, 1.0 ); //display: 0xffff00
        rimLight.position.set(-0.35,0.70,-0.35);
        rimLight.lookAt(0,0,0);
        scene.add( rimLight );
        camera.add( rimLight );

        return scene;
    }

    buildRendered = () => {
        var renderer = new WebGLRenderer( { antialias: true } );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize(window.innerWidth > 970 ? 970 : window.innerWidth-30, window.innerHeight > 545.63 ? 545.63 : window.innerHeight-30 );
        renderer.toneMapping = ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = PCFSoftShadowMap;

        return renderer;
    }

    buildControls = (camera, renderer) => {
        let controls = new OrbitControls( camera, renderer.domElement );
        controls.minDistance = .05;
        controls.maxDistance = 5;

        return controls;
    }

    buildGround = () => {
        const groundGeometry = new PlaneBufferGeometry( 15, 15, 32, 32 );
        const groundMaterial = new ShadowMaterial();
        groundMaterial.opacity = 0.4;
        const ground = new Mesh( groundGeometry, groundMaterial );
        ground.rotateX( - Math.PI / 2 );
        ground.position.y = -0.026;
        ground.receiveShadow = true;

        return ground;
    }

    componentDidMount() {
        let camera = this.buildCamera();
        scene = this.buildScene(camera);

        let renderer = this.buildRendered();
        let controls = this.buildControls(camera, renderer);

        let ground = this.buildGround();
        scene.add( ground );

        matrix = new Matrix4();

        window.addEventListener( 'resize', onWindowResize, false );

        // document.body.appendChild( renderer.domElement );
        // use ref as a mount point of the Three.js scene instead of the document.body
        this.mount.appendChild( renderer.domElement );

        // voxels
        const voxelSize = 0.70;
        geometry = new SphereBufferGeometry( voxelSize, 5, 5 );
        material = new MeshStandardMaterial();

        const loader = new VOXLoader();
        //let url = window.location.origin + '/torus-144.vox';
        //let url = window.location.origin + '/numbers-144x144x144.vox';
        //let url = window.location.origin + '/rgba-72.vox';
        //let url = window.location.origin + '/nike-flyknit-lunar-3-36x17x14.vox';
        //let url = window.location.origin + '/nike-flyknit-lunar-3-72x33x28.vox';      //WORKING LOW RES OBJECT
        //let url = window.location.origin + '/nike-flyknit-lunar-3-144x65x55.vox';
        //let url = window.location.origin + '/nike-flyknit-lunar-3-511x229x194.vox';
        let url = window.location.origin + '/nike-flyknit-lunar-3-999.vox';           //GOAL OBJECT
        //let url = window.location.origin + '/solomon-shoe-72.vox';
        console.log("chunks1")
        loader.load( url, function ( chunks ) {
        //loader.load( window.location.origin + '/solomon-shoe-res72.vox', function ( chunks ) {
            console.log(chunks)
            console.log("end of chunks1");

            loadedChunks = chunks;
            globalPallete = chunks.pallete;
        } );

        function onWindowResize() {
            camera.aspect = (window.innerWidth > 970 ? 970 : window.innerWidth-30) / (window.innerHeight > 545.63 ? 545.63 : window.innerHeight-30);
            camera.updateProjectionMatrix();
            //renderer.setSize(window.innerWidth*0.5, window.innerHeight*0.5);
            renderer.setSize(window.innerWidth > 970 ? 970 : window.innerWidth-30, window.innerHeight > 545.63 ? 545.63 : window.innerHeight-30 );
        }

        console.log("chunks2");
        const _loadVox = async () => {
            let o;
            //let url = "model/nike-flyknit-lunar-3-cs255-gs1000.vox";
            //let url = "/nike-shoe-999.vox";
            try {
                o = await new Promise((accept, reject) => {
                    console.log("chunks3_loader");
                    return new VOXLoaderAvaer({
                        scale: 0.001,
                    }).load(url, accept, function onprogress() {}, reject);
                });
            }
            catch(err) {
                console.log("error");
                console.log(err);
            }
            return o;
        };
        console.log("chunks2_2");
        (async () => {
            const VOXmesh = await _loadVox();
            console.log("chunks2_3");
            console.log(VOXmesh);
            // Adding Avaer mesh: scene.add(VOXmesh);
        })();
        console.log("chunks2_4");

        // HEX

        //fs.readFile('public/nike-flyknit-lunar-3-999.vox', (err, data) => {
        let t = fetch(url)
            .then(res => res.arrayBuffer())
            .then(data => {
                //if (err) throw err;

                var vox = Reader.read(data, (vox, err) => {
                    if (err) throw err;

                    console.log("LOOOOOO")
                    console.log(vox.sizes);
                    console.log(vox.models);
                    console.log(vox.materials);
                    console.log(vox.nodeGraph);
                    console.log("END LOOOOOO");

                    Nodes.walkNodeGraph(vox, walker);
                });
                console.log("vox:");
                console.log(vox);
                console.log("end of vox:")
            }).catch(err => {
                throw err;
            })

        // END HEX

        var animate = function () {
            renderer.render( scene, camera );
            requestAnimationFrame( animate );
        };
        animate();
    }

    render() {
        return (
            <div>
                <div style={{padding:10}}>
                    {/*<Button onClick={() => LoadToFile()}>Load to File</Button> */}
                    <Button onClick={() => DoNext()}>Stream Next Data</Button>
                    <Button onClick={() => DoAll()}>Stream All Data</Button>
                </div>
                <div ref={ref => (this.mount = ref)} style={{paddingRight:100}} />
                <div style={{padding:10}} />
            </div>
        );
    }
}

export default Shoes;
