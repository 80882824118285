import React from "react";

export const IlmlPlayer = () => {
    return(
        <div className="sketchfab-embed-wrapper">
            <iframe title="A 3D model"
                    width="640"
                    height="480"
                    src="https://app.ilivemylife.io/item/000001755176ed23-2e335ae7fd300001"
                    frameBorder="0" allow="autoplay; fullscreen; vr"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true" />
        </div>
    );
}