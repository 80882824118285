import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import './AppHeader.css';
import { Layout, Menu, Dropdown } from 'antd';
import { HomeOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import SubMenu from "antd/es/menu/SubMenu";
import Nav from "./Navbar";


class AppHeader extends Component
{
    constructor(props)
    {
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    handleMenuClick({ key })
    {
        if (key === "logout")
        {
            this.props.onLogout();
        }
    }

    render()
    {
        let menuItems;
        if (this.props.currentUser)
        {
            menuItems = [
                <Menu.Item key="/">
                    <Link to="/">
                        <HomeOutlined className="nav-icon" />
                    </Link>
                </Menu.Item>
            ];
        } else
        {
            menuItems = [
                <SubMenu key="SubMenu" title="Use Cases">
                    <Menu.ItemGroup title="eCommerce">
                        <Menu.Item key="/shoes">
                            <Link to={"/shoes"}>Product Sample</Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup title="Bio-Medicine">
                        <Menu.Item key="/human">
                            <Link to={"/human"}>Human Body</Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </SubMenu>,
                <Menu.Item key="/ilml">
                    <Link to={"/ilml"}>Project Map</Link>
                </Menu.Item>,
                // <Menu.Item key="/sketchfab">
                //     <Link to={"/sketchfab"}>Sketchfab</Link>
                // </Menu.Item>
            ];
        }

        return (
            <Nav Items={menuItems} location="this.props.location.pathname" />
        );
    }
}
/*
function ProfileDropdownMenu(props) {
  const dropdownMenu = (
    <Menu onClick={props.handleMenuClick} className="profile-dropdown-menu">
      <Menu.Item key="user-info" className="dropdown-item" disabled>
        <div className="user-full-name-info">
          {props.currentUser.displayName ? props.currentUser.displayName : null}
        </div>
        <div className="username-info">
          @{props.currentUser.username}
        </div>
      </Menu.Item>
      <Menu.Divider />
        {/*
      <Menu.Item key="profile" className="dropdown-item">
        <Link to={`/users/${props.currentUser.username}`}>Profile</Link>
      </Menu.Item>
      }
      <Menu.Item key="logout" className="dropdown-item">
           Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={dropdownMenu}
      trigger={['click']}
      getPopupContainer = { () => document.getElementsByClassName('profile-menu')[0]}>
      <span className="ant-dropdown-link">
         <UserOutlined className="nav-icon" style={{marginRight: 0}} />
         <DownOutlined />
      </span>
    </Dropdown>
  );
}
*/
export default withRouter(AppHeader);
