import React from "react";

export const SketchfabPlayer = () => {
    return(
        <div className="sketchfab-embed-wrapper">
            <iframe title="A 3D model"
                    width="640"
                    height="480"
                    src="https://sketchfab.com/3d-models/3d-product-sample-12d160c35df04466b4b9a1d5b54cfb7d/embed?autostart=0&amp;ui_controls=1&amp;ui_infos=1&amp;ui_inspector=1&amp;ui_stop=1&amp;ui_watermark=1&amp;ui_watermark_link=1"
                    frameBorder="0" allow="autoplay; fullscreen; vr"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true" />
            <p style={{fontSize: 13, fontWeight: 'normal', margin: 5, color: "#4A4A4A"}}>
                <a href="https://sketchfab.com/3d-models/3d-product-sample-12d160c35df04466b4b9a1d5b54cfb7d?utm_medium=embed&utm_source=website&utm_campaign=share-popup"
                   target="_blank" style={{fontWeight: 'bold', color: '#1CAAD9'}}>Salomon WRS</a>
                by <a
                href="https://sketchfab.com/scanlabstudio?utm_medium=embed&utm_source=website&utm_campaign=share-popup"
                target="_blank" style={{fontWeight: 'bold', color: '#1CAAD9'}}>Scanlab Photogrammetry</a>
                on <a href="https://sketchfab.com?utm_medium=embed&utm_source=website&utm_campaign=share-popup"
                      target="_blank" style={{fontWeight: 'bold', color: '#1CAAD9'}}>Sketchfab</a>
            </p>
        </div>
    );
}