import React, {Component} from "react";
import './App.css';
import {
  Route,
  BrowserRouter,
  Switch
} from 'react-router-dom';

import Presentation from '../presentation/Presentation';
import Shoes from '../presentation/Shoes';
import Human from '../presentation/Human';
//import AvaerPlayer from "../presentation/AvaerPlayer";
import AppHeader from '../common/AppHeader';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import AppFooter from '../common/AppFooter';

import { Layout } from 'antd';
import {IlmlPlayer} from "../Players/IlmlPlayer";
import {SketchfabPlayer} from "../Players/SketchfabPlayer";
const { Content, Footer } = Layout;

class App extends Component {

    render() {
        return (
        <BrowserRouter>
            <Layout className="app-container">
                <AppHeader />
                <Content className="app-content">
                    <div className="container">
                        <Switch>
                            <Route exact path="/" render={(props) => <Presentation />}>
                            </Route>
                            <Route path="/shoes" render={(props) => <Shoes />}>
                            </Route>
                            <Route path="/human" render={(props) => <Human />}>
                            </Route>
                            <Route path="/ilml" render={(props) => <IlmlPlayer />}>
                            </Route>
                            <Route path="/sketchfab" render={(props) => <SketchfabPlayer />}>
                            </Route>
                            <Route component={NotFound}></Route>
                        </Switch>

                    </div>
                </Content>
                <Footer className="footer-style">
                    <AppFooter />
                </Footer>
            </Layout>
        </BrowserRouter>
        );
    }
}

export default App;