import React, { Component } from 'react';
import {Button} from 'antd';
import './Presentation.css';

class Presentation extends Component {
    render() {
        console.log("presentation")
        return (
            <div className={"presentation-center"}>
                <h2>A High-Definition 3D Streaming Platform and an API for Mobile and Web-based Augmented, Virtual and Mixed Reality Applications</h2>
                <br/>
                <Button type="primary">
                    <a href="/shoes">View Demo</a>
                </Button>
            </div>
        );
    }
}

export default Presentation;
